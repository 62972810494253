import Api from './Api'

export function loginApi(data){
    return Api.post('/session/login',{ ...data } )
}

export function colegiaSSOLoginApi(data){
    return Api.post('/session/colegia/sso/login',{ ...data } )
}

export function externalLoginApi(data){
    return Api.post('/session/partners/colegia/external/login',{ ...data } )
}

export function partnerLoginApi(data){
    return Api.post('/session/partners/colegia/login/web',{ ...data } )
}

export function changeExternalUserPassword(data){
    return Api.post('/user/password/reset/external_user',{ ...data } )
}

export function checksession(data){
    console.log(data)
    return Api.get('/v2/check-session',{ params: { ...data } })
}

export function logoutUser(data){
    return Api.post('/session/web/logout', { ...data } )
}

export function partnerDashboardSummary(data){
    return Api.post('/v2/managed_user/reflect/dashboard/summary', {...data})
}

export function continueListeningSummary(data){
    return Api.post('/v2/managed_user/mindfulness/session_listened/list', {...data})
}

export function getUserFavouritesList(data){
    return Api.post('/v2/managed_user/mindfulness/favourites/list', {...data})
}

export function getMindfulnessSessions(data){
    return Api.post('/v2/managed_user/mindfulness/sessions/list', {...data})
}

export function getAdminMindfulnessSessions(data){
    return Api.post('/v2/managed_user/mindfulness/admin/sessions/list', {...data})
}

export function sessionInfoList(data){
    return Api.post('/v2/managed_user/mindfulness/session/info', {...data})
}

export function sessionInfoListwithFilter(data){
    return Api.post('/v2/managed_user/mindfulness/filtered/session/info', {...data})
}

export function addFavourites(data){
    return Api.post('/v2/managed_user/mindfulness/favourites/add', {...data})
}

export function deleteFavourites(data){
    return Api.post('/v2/managed_user/mindfulness/favourites/delete', {...data})
}

export function listenedUpdate(data){
    return Api.post('/v2/managed_user/mindfulness/session_listened/update', {...data})
}

export function getAmbienceList(data){
    return Api.post('/organization/ambience/audio/list', {...data})
}

export function createSelfHappienssResponse(data){
    return Api.post('/self/happiness/response/create', {...data})
}

export function createManagedUserHappienssResponse(data){
    return Api.post('/managed_user/happiness/response/create', {...data})
}

export function getHappinessSummary(data){
    return Api.post('v2/managed_user/reflect/summary', {...data})
}

export function getHappinessQuestion(data){
    return Api.post('organization/happiness/questions/list', {...data})
}

export function getHappinessSetting(data){
    return Api.post('v2/managed_user/happiness/settings/list', {...data})
}

export function AddHappinessSetting(data){
    return Api.post('v2/managed_user/happiness/setting/add', {...data})
}

export function UpdateHappinessSetting(data){
    return Api.post('v2/managed_user/happiness/setting/update', {...data})
}

export function getHappinessHistory(data){
    return Api.post('v2/managed_user/reflect/history', {...data})
}

export function getActivitySummary(data){
    return Api.post('v2/managed_user/activity/summary', {...data})
}

export function getActivityHistory(data){
    return Api.post('v2/managed_user/activity/history', {...data})
}

export function addUnitApi(data){
    return Api.post('admin/learn/unit/add', {...data})
}

export function editUnitApi(data){
    return Api.post('admin/learn/unit/edit', {...data})
}

export function deleteUnitApi(data){
    return Api.post('admin/learn/unit/delete', {...data})
}

export function listUnitApi(data){
    return Api.post('admin/learn/unit/list', {...data})
}

export function updateUnitStatusApi(data){
     return Api.post('admin/learn/unit/update/status', {...data})
}

export function getUnitInfoApi(data){
     return Api.post('admin/learn/unit/info', {...data})
}

export function updateDisciplineApi(data){
     return Api.post('admin/learn/discipline/edit', {...data})
}

export function getDisciplineFileUploadUrlsAPI(data, disciplineID) {
  return Api.post(`admin/learn/discipline/${disciplineID}/file_upload_urls`,  { ...data });
}

export function getUnitFileUploadUrlsAPI(data, unitUUID) {
  return Api.post(`admin/learn/unit/${unitUUID}/file_upload_urls`,  { ...data });
}

export function unitFileAttachmenDownloadAPI(data, unitUUID) {
  return Api.post(`admin/learn/unit/${unitUUID}/download_attachment`,  { ...data });
}

export function fileAttachmentDeleteAPI(data) {
  return Api.post(`admin/learn/attachment/delete`,  { ...data });
}

export function addLessonApi(data){
    return Api.post('admin/learn/lesson/add', {...data})
}

export function editLessonApi(data){
    return Api.post('admin/learn/lesson/edit', {...data})
}

export function deleteLessonApi(data){
    return Api.post('admin/learn/lesson/delete', {...data})
}

export function updateLessonNumberApi(data){
    return Api.post('admin/learn/lesson/lesson_number/update', {...data})
}

export function getResiliencyStandardsApi(data){
     return Api.post('admin/learn/lesson/resilency_standards', {...data})
}

export function getLessonInfoApi(data){
     return Api.post('admin/learn/lesson/info', {...data})
}

export function updateLessonAttachmentApi(data){
    return Api.post('admin/learn/lesson/attachments/update', {...data})
}

export function updateLessonSubSectionApi(data){
    return Api.post('admin/learn/lesson/components/update', {...data})
}

export function getLessonFileUploadUrlsAPI(data, lessonUUID) {
  return Api.post(`admin/learn/lesson/${lessonUUID}/file_upload_urls`,  { ...data });
}

export function getCompetencyAndUnitsInfo(data){
    return Api.post('/admin/learn/competency_and_unit_info', {...data})
}

export function duplicateLessonsApi(data){
    return Api.post('/admin/learn/duplicate_lessons', {...data})
}


export function uploadFiletoS3Api(urlInfo, fileInfo, setProgressFn) {
  const file = fileInfo;
  const config = {
    headers: {
      'Content-Type': file.type
    },
    onUploadProgress: (progressEvent) => {
      const progress = progressEvent.loaded / progressEvent.total;
      if (setProgressFn) {
        setProgressFn(progress * 100);
      }
    }
  };
  return Api.put(urlInfo.upload_url, file, config);
}

export function downloadFilefromS3Api(urlInfo, fileInfo, setProgressFn) {
  const file = fileInfo;
  const config = {
    headers: {
      'Content-Type': file.type
    }
  };
  return Api.get(urlInfo.download_url, file, config);
}

export function getAwsPresignedURL(data) {
  return Api.post(`presigned_url/get`,  { ...data });
}

export function teacherUnitListApi(data){
    return Api.post('/teacher/learn/unit/list', {...data})
}

export function teacherUnitInfoApi(data){
    return Api.post('/teacher/learn/lesson/list', {...data})
}

export function teacherLessonInfoApi(data){
    return Api.post('/teacher/learn/lesson/info', {...data})
}

export function teacherResiliencyStandardApi(data){
    return Api.post('/teacher/learn/resiliency_standards', {...data})
}

export function UsageLogApi(data){
    return Api.post('/analytics/usage/update', {...data})
}

export function createFolderApi(data){
    return Api.post('/admin/resource/folder/create', {...data})
}

export function getUploadFolderUrlsApi(data){
    return Api.post('/admin/resource/folder/upload_urls', {...data})
}

export function uploadFolderApi(data){
    return Api.post('/admin/resource/folder/upload', {...data})
}

export function moveFolderApi(data){
    return Api.post('/admin/resource/folder/move', {...data})
}

export function renameFolderApi(data){
    return Api.post('/admin/resource/folder/rename', {...data})
}

export function deleteFolderApi(data){
    return Api.post('/admin/resource/folder/delete', {...data})
}

export function getAllFoldersListApi(data){
    return Api.post('/admin/resource/folder/list/all', {...data})
}

export function getFoldersListApi(data){
    return Api.post('/admin/resource/folder/list', {...data})
}

export function downloadFolderApi(data){
    return Api.post('/admin/resource/folder/download', {...data})
}

export function getUploadFileUrlsApi(data){
    return Api.post('/admin/resource/file/upload_urls', {...data})
}

export function uploadFileApi(data){
    return Api.post('/admin/resource/file/upload', {...data})
}

export function moveFileApi(data){
    return Api.post('/admin/resource/file/move', {...data})
}

export function renameFileApi(data){
    return Api.post('/admin/resource/file/rename', {...data})
}

export function deleteFileApi(data){
    return Api.post('/admin/resource/file/delete', {...data})
}

export function getTeacherFoldersListApi(data){
    return Api.post('/teacher/resource/folder/list', {...data})
}

export function addJournalApi(data){
    return Api.post('/v2/managed_user/journal/response/add', {...data})
}

export function updateJournalApi(data){
    return Api.post('/v2/managed_user/journal/response/update', {...data})
}

export function deleteJournalApi(data){
    return Api.post('/v2/managed_user/journal/response/delete', {...data})
}

export function getJournalSummaryApi(data){
    return Api.post('/v2/managed_user/journal/summary', {...data})
}

export function getCMSPageInfo(data){
    return Api.post('/admin/cms/page/info', {...data})
}

export function updateCMSPageInfo(data){
    return Api.post('/admin/cms/page/update', {...data})
}

export function publishCMSPageInfo(data){
    return Api.post('/admin/cms/page/publish', {...data})
}

export function getCMSUploadFileUrlsApi(data){
    return Api.post('/admin/cms/file/upload_urls', {...data})
}


export function getContentPageInfo(data){
    return Api.post('/content/page/info', {...data})
}

export function getCustomExerciseDefaultConfigs(data){
    return Api.post('/v2/custom_exercise/config/defaults', {...data})
}

export function getMindfulnessDraftApi(data){
    return Api.post('/v2/custom_exercise/generate/script', {...data})
}

export function createCustomExerciseApi(data){
    return Api.post('/v2/custom_exercise/create', {...data})
}

export function regenerateAudioApi(data){
    return Api.post('/v2/custom_exercise/generate/audio', {...data})
}

export function regenerateImageApi(data){
    return Api.post('/v2/custom_exercise/regenerate/image', {...data})
}

export function regenerateDraftApi(data){
    return Api.post('/v2/custom_exercise/regenerate/script', {...data})
}

export function getCustomExercisesApi(data){
    return Api.post('/v2/custom_exercise/exercises/list', {...data})
}

export function deleteCustomVoiceApi(data){
    return Api.post('/v2/custom_exercise/voice/delete', {...data})
}

export function CustomExerciseDraftInfoApi(data){
    return Api.post('/v2/custom_exercise/draft/info', {...data})
}

export function CustomExerciseDeleteDraftApi(data){
    return Api.post('/v2/custom_exercise/draft/delete', {...data})
}

export function getDefaultArticlesApi(data){
    return Api.post('/article/default', {...data})
}

export function getArticleListApi(data){
    return Api.post('/article/list', {...data})
}

export function getArticleInfoApi(data){
    return Api.post('/article/slug', {...data})
}

export function syncWordpressMigrationApi(data){
    return Api.post('/admin/article/sync', {...data})
}

export function updateFeatureTourApi(data){
    return Api.post('/organization/user/feature/status/update', {...data})
}


export function updateWebNotificationApi(data){
    return Api.post('/organization/user/notification/web/update', {...data})
}

export function registerNotificationApi(data){
    return Api.post('/user/notifications/register/web', {...data})
}