import { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from 'react-dom/client';
import CEThumbnail from '../../assets/images/feature_tour_custom_exercise.png'
import '../../assets/stylesheets/shared/feature_tour.scss'
import LazyLoad from "react-lazyload";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../services/SessionProvider";
import { getUserData, saveUserData } from "../../services/helpers";
import { updateFeatureTourApi } from "../../services/Events";

export const CustomExerciseFeatureTour = ({setUserTour, setUserInfo}) =>{
    const timerRef = useRef(null);
    const [showTour, setShowTour] = useState(false)
    const navigate = useNavigate();
    const userData = getUserData();
    async function handleClick(type){

        setShowTour(false);
        setUserTour('completed')
        
        const tooltip_id = document.getElementById('custom_exercise_tooltip_id');

        if(tooltip_id){
            tooltip_id.remove();
        }

        let payload = { mwsRequest:{ String: 
            {Request:
            {   Session: { token: '' ,loggedin_user_type: userData.user_info.user_type},
                Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
                Api: {version: '1.0'},
                ManagedUser: {uuid: userData.user_info.user_account_uuid},
                Feature: {status: {}}
          
          }}}}
         
        if (userData.user_info?.feature_tour && typeof userData.user_info?.feature_tour === 'object') {
            const featureStatus = userData.user_info?.feature_tour;
          
            if (featureStatus.dashboard) {
              if (!featureStatus.dashboard.includes('custom_exercise')) {
                featureStatus.dashboard.push('custom_exercise');
              }
            } else {
              featureStatus.dashboard = ['custom_exercise'];
            }
            payload.mwsRequest.String.Request.Feature.status = featureStatus;
        }

        if(type === 'submit'){
            navigate('/mindfulness/custom_exercise/add')
        }

        try{
            const response = await updateFeatureTourApi(payload);
            if(response.data.mwsResponse.Status.code === '000'){
                setUserInfo((prevUserInfo) => {
                    const updatedUserData = { ...userData };
                    updatedUserData.user_info.feature_tour = payload.mwsRequest.String.Request.Feature.status;
                    saveUserData(updatedUserData);
                    return updatedUserData;
                  });
            }
            else{
                console.log(response.data)
            }

        }
        catch(error){
            console.log(error)
        }
    }
    const tourElement =  <div className="custom_exercise_feature_tour_wrapper">
                <div className="custom_exercise_feature_tour_tooltip_container"><div className="custom_exercise_feature_tour_tooltip"></div></div>
                <div className="custom_exercise_feature_tour_container">
                    <LazyLoad>
                        <img src={CEThumbnail} className="feature_tour_image_container custom_exercise" alt="Custom Exercise"/>
                    </LazyLoad>
                    <div className="feature_tour_header custom_exercise">Create Custom Exercise</div>
                    <div className="feature_tour_description custom_exercise">Design a unique, personalized playlist of thought exercises crafted just for you</div>
                    <div className="feature_tour_btn_container">
                        <button className="feature_tour_btn custom_exercise submit" onClick={()=>{
                                handleClick('submit')  
                            }
                            }>Get Started</button>
                        <button className="feature_tour_btn custom_exercise cancel" onClick={()=>{handleClick('skip')}}>Skip</button>
                    </div>
                </div>
            </div>

    useEffect(() => {
        timerRef.current = setInterval(() => {
        handleRender();
        }, 1000);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        };
    }, [window.innerWidth]);

    const handleRender = () => {
        clearInterval(timerRef.current); // Clear the interval once the element is found

        const customExerciseId = document.getElementById(
            window.innerWidth > 1100 ? 'add_custom_exercise_id' : 'add_custom_exercise_responsive_id'
        );

        if (customExerciseId) {
            setShowTour(true)
            const newDiv = document.createElement('div');
            newDiv.id = "custom_exercise_tooltip_id"
            if(window.innerWidth > 1100){
                newDiv.style.position = 'absolute'; 
                newDiv.style.top = 0;
                newDiv.style.zIndex = '6';
                newDiv.style.marginTop = '64px';
                newDiv.style.marginLeft = '-180px';
                customExerciseId.insertAdjacentElement('afterend', newDiv);
            }
            else{
                newDiv.style.position = 'absolute'; 
                newDiv.style.right = 0;
                newDiv.style.zIndex = '6';
                newDiv.style.marginTop = '4px';
                newDiv.style.marginRight = '12px';
                customExerciseId.appendChild(newDiv);
            }
            const root = ReactDOM.createRoot(newDiv)
            root.render(tourElement);
        }
    };



    return showTour && <div className="feature_tour_wrapper">
                <div className="feature_tour_container">
                </div>
            </div>
}