import { Routes, Route } from "react-router-dom";
import StateRoute from './services/StateRoute';
import TeacherRoutes from './services/TeacherRoutes'
import AdminRoute from './services/AdminRoute';
import { Suspense,lazy, useEffect, useState, useRef } from 'react';
import Loader from './Components/shared/Loader';
import CustomExerciseLoader from './Components/shared/CustomExerciseLoader';
import { ToastContainer } from "react-toastify"; 
import UsageTracking from './services/UsageTracking';
import {useIdleTimer} from 'react-idle-timer'
import {  logoutUser } from './services/Events';
import { getUserData } from "./services/helpers";
import AppRoutes from "./services/AppRoutes";
import ContentRoutes from "./services/ContentRoutes";
import AlaiawebRoute from "./services/AlaiawebRoute";
import { CustomExerciseFeatureTour } from "./Components/shared/FeatureTour";

const Home = lazy(() => import('./Components/Content/Home'));
const Learn = lazy(() => import('./Components/Content/Learn'));
const Wellness = lazy(() => import('./Components/Content/Wellness'));
const Login = lazy(() => import('./Components/Content/Login'));
const AboutUs = lazy(() => import('./Components/Content/AboutUs'));
const Impact = lazy(() => import('./Components/Content/Impact'));
const Article = lazy(() => import('./Components/Content/Article'));
const Privacy = lazy(() => import('./Components/Content/Privacy'));
const Terms = lazy(() => import('./Components/Content/Terms'));
// const Signup = lazy(() => import('./Components/Content/SignUp'));
// const Blog = lazy(() => import('./Components/Content/Blog'));
const ColegiaLogin = lazy(() => import('./Components/Content/ColegiaLogin'));
const ChangePassword = lazy(()=> import('./Components/shared/ChangePassword'))
const MindfulnessHome = lazy(() => import("./Components/Mindfulness/Home"));
const SeriesSessionList = lazy(() => import("./Components/Mindfulness/SeriesSessionList"));
const CollectionSessionList = lazy(() => import("./Components/Mindfulness/CollectionSessionList"));
const IndividualSessionList = lazy(() => import("./Components/Mindfulness/IndividualSessionList"));
const ContinueListeningList = lazy(() => import("./Components/Mindfulness/ContinueListeningList"));
const Favourites = lazy(() => import("./Components/Mindfulness/UserFavouritesList"));
const ThoughtExerciseList = lazy(() => import("./Components/Mindfulness/ThoughtExerciseList"));
const CustomExerciseList = lazy(() => import("./Components/Mindfulness/CustomExerciseList"));
const SeriesList = lazy(() => import("./Components/Mindfulness/SeriesList"));
const CollectionList = lazy(() => import("./Components/Mindfulness/CollectionList"));
const AudioPlayer = lazy(() => import("./Components/shared/AudioPlayer"));
const ReflectHome = lazy(() => import("./Components/Reflect/Home"));
const ReflectEdit = lazy(() => import("./Components/Reflect/EditReflect"));
const ReflectEditQuestion = lazy(() => import("./Components/Reflect/EditReflectQuestion"));
// const ReflectAnalytics = lazy(()=> import('./Components/Reflect/ReflectAnalytics_2'))
const ReflectAnalytics = lazy(() => import("./Components/Reflect/ReflectAnalytics"));
// const ReflectAnalyticPeriod = lazy(()=> import('./Components/Reflect/ReflectAnalyticPeriod'))
const JournalHome = lazy(() => import("./Components/Journal/Home"));
const HomeSearch = lazy(() => import("./Components/Mindfulness/HomeSearch"));
const ActivityHome = lazy(() => import("./Components/Activity/Home"));
const ActivityAnalytics = lazy(() => import("./Components/Activity/ActivityAnalytics"));
// const LineGraph = lazy(()=> import('./Components/Reflect/WeekLineChart'))
const TeacherHome = lazy(()=> import("./Components/Teacher/Learn/Home"))
const TeacherLearnGrades = lazy(()=> import("./Components/Teacher/Learn/LearnGrades"))
const TeacherLearnGradeList = lazy(()=> import("./Components/Teacher/Learn/GradeList"))
const TeacherUnitList = lazy(()=> import("./Components/Teacher/Learn/UnitList"))
const TeacherUnitContent = lazy(()=> import("./Components/Teacher/Learn/UnitContent"))
const TeacherLessonContent = lazy(()=> import("./Components/Teacher/Learn/LessonContent"))
const Previewer = lazy(() => import("./Components/Teacher/Learn/Previewer"));
const LearnHome = lazy(() => import("./Components/Admin/Learn/Home"));
// const ListUnit = lazy(() => import("./Components/Admin/Learn/ListUnit"));
const GradeList = lazy(()=> import("./Components/Admin/Learn/GradeList"));
const UnitsList = lazy(() => import("./Components/Admin/Learn/UnitsList"));
const AddUnit = lazy(() => import("./Components/Admin/Learn/AddUnit"));
const EditUnit = lazy(() => import("./Components/Admin/Learn/EditUnit"));
const UnitView = lazy(() => import("./Components/Admin/Learn/UnitView"));
const AddLesson = lazy(() => import("./Components/Admin/Learn/AddLesson"));
const LessonView = lazy(() => import("./Components/Admin/Learn/LessonView"));
const AWSView = lazy(() => import("./Components/shared/AWSView"));

const Resources = lazy(() => import("./Components/Admin/Learn/Resources/Home"));
const TeacherResources = lazy(()=> import("./Components/Teacher/Learn/Resources/Home"))

const CMSHome = lazy(()=> import("./Components/Admin/CMS/Home"))
const ContentTemplate = lazy(()=> import("./Components/Admin/CMS/ContentTemplate"))
const CTEHome =  lazy(()=> import("./Components/Mindfulness/CTEHome"))
const AddCustomExercise =  lazy(()=> import("./Components/Mindfulness/AddCustomExercise"))
const CustomExerciseAdd =  lazy(()=> import("./Components/Mindfulness/CustomExerciseAdd"))
const CustomExerciseEdit =  lazy(()=> import("./Components/Mindfulness/CustomExerciseEdit"))
const CustomExerciseVoices = lazy(()=> import("./Components/Mindfulness/CustomExerciseVoice"))

const NoMatch = lazy(()=>import('./Components/shared/NoMatch'))

if (window.location.href.indexOf("admin") > -1) {
    localStorage.setItem('alaia_admin_redirect_url', window.location.href)
}
function App() {

  const userInfoRef = useRef();
  const [userInfo, setUserInfo] = useState({});
  const [userTour, setUserTour] = useState(true);
   async function userlogout(){
     
        let payload = 
          { mwsRequest:{ String: 
            {Request:
            { Session: { token: '' ,loggedin_user_type: userInfo.user_type, auto_logout: true},
              Client: {platform: 'web',identity: '', version:"1.0", session_hash:""}
          }}}}

        try {         
            const response = await logoutUser(payload)

            if(response){
            if (response.data.mwsResponse.Status.code === '000'){
                console.log(response.data)
            }
            else{
            let error = response.data.mwsResponse.Status.message
            console.log(error)
            }
        }
        } catch (error) {
            console.log(error)
        }
    }


  const onIdle = () =>{
    if(isLastActiveTab())
      userlogout();
  }
   const {
    getRemainingTime,
    getTabId,
    isLeader,
    isLastActiveTab,
    message
  } = useIdleTimer({
    onIdle, 
    timeout: 3600 * 1000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 1
  })
  UsageTracking();

  useEffect(() => {
    userInfoRef.current = setInterval(() => {
      if(Object.keys(getUserData()?.user_info || {}).length > 0){
        setUserInfo((prevUserInfo) => {
          if (Object.keys(prevUserInfo).length === 0) {
            const user_data = getUserData()?.user_info;
            return user_data || {};
          } else {
            clearInterval(userInfoRef.current);
            return prevUserInfo; // No change to state
          }
        });
      }
      
    }, 1000);
  
    return () => {
      if (userInfoRef.current) {
        clearInterval(userInfoRef.current);
      }
    };
  }, []);
  

  return (
    <>
    <Loader />
    <CustomExerciseLoader/>
    {/* <IdleStateDetector delay={10000}/> */}
    {/* <SessionProvider> */}
   
     
          <Suspense fallback={<div>Loading...</div>}>
          { <Routes>

            <Route path='/alaiaweb/*' element={<AlaiawebRoute/>}/>
           
            {/* Content Routes */}

            <Route element={<ContentRoutes/>}>
                <Route path = '/' element={<Home/>}/>
                <Route path = "/learn" element={<Learn/>}/>
                <Route path = "/wellness" element={<Wellness/>}/>
                <Route path = '/login' element={<Login/>}/>
                {/* <Route path = '/signup' element={<Signup/>}/> */}
                <Route path = '/about-us' element={<AboutUs/>}/>
                <Route path = '/impact' element={<Impact/>}/>
                <Route path = '/article/:slug' element={<Article/>}/>
                <Route path = '/privacy' element={<Privacy/>}/>
                <Route path = '/terms' element={<Terms/>}/>
                {/* <Route path = '/blog/:slug' element={<Blog/>}/> */}
                <Route path = '/sso/saml_response' element={<ColegiaLogin/>}/>
                <Route path='/change-password' element={<StateRoute rpath={'/login'}/>}>
                  <Route path='/change-password' element={<ChangePassword/>} />
                </Route>
                {/* <Route path="/cms/home" element={<HomeTemplate/>} /> */}
              
            </Route>
            {/* App routes */}

            <Route element={<AppRoutes />}>
              {/* mindfulness routes */}
              <Route path='/home' element={<MindfulnessHome/>} />
              <Route path='/home/search' element={<HomeSearch/>} />
              <Route path='/series/sessions/list/:id' element={<SeriesSessionList/>} />    
              <Route path='/collection/sessions/list/:id' element={<CollectionSessionList/>} />    
              <Route path='/individual/sessions/list/:uuid' element={<IndividualSessionList/>} />
              <Route path='/tracklistened/list' element={<ContinueListeningList/>} />
              <Route path='/favourites/list' element={<Favourites/>} />
              <Route path='/thought-exercise/list' element={<ThoughtExerciseList/>} />
              <Route path='/custom-exercise/list' element={<CustomExerciseList/>} />
              <Route path='/series/list' element={<SeriesList/>} />
              <Route path='/collection/list' element={<CollectionList/>} />
              <Route path='/audio/:uuid' element={<AudioPlayer />} />
                <Route path='/mindfulness/custom_exercise' element={<CTEHome/>}/>
                <Route path='/mindfulness/custom_exercise/add' element={<CustomExerciseAdd/>}/>
                <Route path='/mindfulness/custom_exercise/edit/:uuid' element={<CustomExerciseEdit/>}/>
                <Route path='/mindfulness/custom_exercise/voice' element={<CustomExerciseVoices/>}/>

              {/* reflect routes */}
              <Route path='/reflect' element={<ReflectHome />} />
              <Route path='/reflect/edit' element={<ReflectEdit />} />
              <Route path='/reflect/edit/:uuid' element={<StateRoute rpath={'/reflect/edit'}/>}>
                 <Route path='/reflect/edit/:uuid' element={<ReflectEditQuestion/>} />
              </Route>
              <Route path='/reflect/analytics' element={<ReflectAnalytics/>} />
              {/* <Route path='/reflect/chart' element={<LineGraph />}/> */}
              {/* <Route path='/reflect/analytics/period' element={<ReflectAnalyticPeriod/>} /> */}
              <Route path='/reflect/journal' element={<JournalHome/>} />

              {/* activity routes */}
              <Route path='/activity' element={<ActivityHome />} />
              <Route path='/activity/analytics' element={<ActivityAnalytics />}/>

             {/* Teacher page routes */}
             
              <Route path = '/teacher/learn' element={<TeacherRoutes rpath='/'/>}>
                <Route path = '/teacher/learn' element={<TeacherHome/>}/>
              </Route>

              <Route path = '/teacher/learn/grade/list' element={<TeacherRoutes rpath='/'/>}>
                <Route path ='/teacher/learn/grade/list' element = {<TeacherLearnGradeList/>}/>
              </Route>

              <Route path = '/teacher/learn/grade/:grade' element={<TeacherRoutes rpath='/'/>}>
                <Route path ='/teacher/learn/grade/:grade' element = {<TeacherLearnGrades/>}/>
              </Route>
             
              <Route path = '/teacher/learn/units/:grade/:discipline_id' element={<TeacherRoutes rpath='/'/>}>
                <Route path = '/teacher/learn/units/:grade/:discipline_id' element = {<TeacherUnitList/>} />
              </Route>
              <Route path = '/teacher/learn/:grade/unit/:uuid' element={<TeacherRoutes rpath='/'/>}>
                <Route path = '/teacher/learn/:grade/unit/:uuid' element = {<TeacherUnitContent/>} />
              </Route>
              <Route path = '/teacher/learn/:grade/:unit_uuid/lesson/:uuid' element={<TeacherRoutes rpath='/'/>}>
                <Route path = '/teacher/learn/:grade/:unit_uuid/lesson/:uuid' element = {<TeacherLessonContent/>} />
              </Route>

              <Route path = '/teacher/learn/:type/preview' element={<TeacherRoutes rpath='/'/>}>
                <Route path = '/teacher/learn/:type/preview' element = {<Previewer/>} />
              </Route>
              
              <Route path = '/teacher/learn/resources' element={<TeacherRoutes rpath='/'/>}>
                <Route path = '/teacher/learn/resources' element = {<TeacherResources/>} />
              </Route>

              <Route path = '/teacher/learn/resources/:uuid' element={<TeacherRoutes rpath='/'/>}>
                <Route path = '/teacher/learn/resources/:uuid' element = {<TeacherResources/>} />
              </Route>
            </Route>

            {/* Admin page routes */}

            <Route element={<AdminRoute rpath={'/'}/>}>
              {/* learn routes */}
              <Route path='/admin/learn' element={<LearnHome />}/>
              <Route path='/admin/learn/grade/list' element={<GradeList/>}/>
              <Route path='/admin/learn/units/:grade' element={<UnitsList />}/>
              <Route path='/admin/learn/unit/add' element={<AddUnit />}/>
              <Route path='/admin/learn/unit/edit/:uuid' element={<EditUnit />}/>
              <Route path='/admin/learn/unit/:grade/:uuid' element={<UnitView />}/>
              <Route path='/admin/learn/unit/:grade/:unit_uuid/lesson/add' element={<AddLesson />}/>
              <Route path='/admin/learn/unit/:grade/:unit_uuid/lesson/:uuid' element={<LessonView />}/>
              <Route path='/admin/learn/resources' element={<Resources />}/>
              <Route path='/admin/learn/resources/:uuid' element={<Resources />}/>

               {/* cms routes */}
              <Route path = '/admin/cms' element={<CMSHome/>}/>
              <Route path = '/admin/cms/template/:template_id' element={<ContentTemplate/>}/>

            </Route>
           
            <Route path='/view' element={<AWSView />} />
            <Route path='*' element={<NoMatch/>}/>
          </Routes>}
          {/* <Routes>
             
          </Routes> */}
        </Suspense>
        
        {/* If multiple tour use Context */}
        {window.location.href.split('?')[0] === `https://${window.location.hostname}/home` && Object.keys(userInfo || {}).length > 0 && (userInfo?.feature_tour?.dashboard == null || userInfo?.feature_tour?.dashboard?.indexOf('custom_exercise') === -1) &&
        userTour!=='completed' && <CustomExerciseFeatureTour setUserTour={setUserTour} setUserInfo={setUserInfo}/>}
      
     
      <ToastContainer
        position="bottom-center"
        hideProgressBar={true}
        autoClose={1000}
      />
      {/* </SessionProvider> */}
    </>
  );
}

export default App;
